@font-face {
    font-family: 'eUkraine';
    src: local('eUkraine'), url(./fonts/e-Ukraine-Regular.otf) format('opentype');
}

@font-face {
    font-family: 'eUkraine';
    font-weight: 300;
    src: local('eUkraine'), url(./fonts/e-Ukraine-Light.otf) format('opentype');
}

@font-face {
    font-family: 'eUkraine';
    font-weight: 700;
    src: local('eUkraine'), url(./fonts/e-Ukraine-Bold.otf) format('opentype');
}

@font-face {
    font-family: 'eUkraineHead';
    font-weight: 400;
    src: local('eUkraineHead'), url(./fonts/e-UkraineHead-Regular.otf) format('opentype');
}

@font-face {
    font-family: 'eUkraineHead';
    font-weight: 500;
    src: local('eUkraineHead'), url(./fonts/e-UkraineHead-Medium.otf) format('opentype');
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'eUkraine', Arial, Helvetica, sans-serif;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
/* CSS TO-DO:
1. поправити паддінг на фільтрі між квадратиком і категорі груп
2. зробити більш-менш резиновий вид 
3. перекодувати шрифти в формати woff/woff2 */
