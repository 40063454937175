.footer-wrapper {
    width: 100%;
    padding: 40px 0;
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
}

.footer-wrapper.absolute {
    position: absolute;
    z-index: 100;
    padding-left: 15.8%;
    padding-right: 15.8%;
    background-color: transparent;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-links {
    display: flex;
    justify-content: space-evenly;
    width: 71%;
}

.footer-link {
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    cursor: pointer;
}

.footer-social-media {
    display: flex;
    align-items: center;
    width: 168px;
    justify-content: space-between;
}

.socialm-link {
    width: 24px;
    height: 24px;
    cursor: pointer;
    transition: 0.3s;
}

.socialm-link:hover {
    transform: scale(1.1);
}

@media only screen and (max-width: 1200px) {
    .footer-wrapper.absolute {
        padding-left: 80px;
    }
}

@media only screen and (max-width: 968px) {
    .footer-link {
        font-size: 14px;
        line-height: 22px;
    }
}

@media only screen and (max-width: 768px) {
    .footer-wrapper {
        max-width: unset;
        height: auto;
        padding: 16px 0 40px;
    }

    .footer-content {
        flex-direction: column;
        align-items: flex-start;
    }

    .footer-links {
        flex-direction: column;
        align-items: flex-start;
    }

    .footer-link {
        margin-top: 24px;
    }

    .footer-link:last-child {
        margin-bottom: 24px;
    }

    .footer-wrapper.absolute {
        position: static;
        padding: 24px 16px;
    }
}
