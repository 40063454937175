.application-wrapper {
    width: 100%;
    max-width: 1352px;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
}

.application-header {
    width: 100%;
    background-color: #ffffff;
    display: flex;
    justify-content: end;
    position: relative;
    height: 100px;
}

.sub-header {
    background: #ffffff;
}

.application-form-wrapper {
    width: 100%;
    padding: 40px 0;
    background-color: #ffffff;
}

@media only screen and (max-width: 768px) {
    .application-form-wrapper {
        padding: 24px 0;
    }
    .application-form {
        width: auto;
    }
    .fields-row {
        flex-direction: column;
    }
    .sub-header {
        padding: 0;
    }
}

@media only screen and (max-width: 600px) {
    .application-form-wrapper {
        padding: 24px 0;
    }
    .application-form {
        width: auto;
    }
    .fields-row {
        flex-direction: column;
    }
    .sub-header {
        padding: 0;
    }
}
