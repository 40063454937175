.overlay-wrapper {
    position: fixed;
    display: flex;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 2001;
}

.overlay-background {
    background-color: #000000;
    opacity: 0.6;
    height: 100%;
    width: 100%;
}

.filter-categories-block {
    min-width: 496px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #ffffff;
}

.filter-items-wrapper {
    width: 100%;
    height: 100%;
    padding: 48px;
}

.filter-title-wrapper {
    box-shadow: inset 0px -1px 0px #cacaca;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 24px;
    margin-bottom: 24px;
}

.filter-title {
    color: #131313;
    font-family: 'eUkraineHead';
    font-weight: 400;
    font-size: 24px;
    line-height: 38px;
}

.filter-close-image {
    height: 12px;
    cursor: pointer;
}

.filter-group-wrapper {
    box-shadow: inset 0px -1px 0px #cacaca;
    padding-bottom: 16px;
    margin-bottom: 16px;
}

.filter-group-wrapper:last-child {
    box-shadow: none;
    padding-bottom: 0;
    margin-bottom: 0;
}

.filter-group-title-wrapper {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 16px;
}

.filter-group-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
}

.filter-buttons {
    height: 92px;
    box-shadow: 0px -1px 0px #d8d8d8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
}

@media only screen and (max-width: 768px) {
    .overlay-wrapper {
        height: calc(100vh - calc(100vh - 100%));
    }
    .filter-items-wrapper {
        padding: 16px;
        overflow-y: auto;
    }
    .filter-categories-block {
        min-width: unset;
        width: 100%;
    }
    .filter-buttons {
        box-shadow: none;
    }
    .clear-button,
    .apply-button {
        width: auto;
    }
    .overlay-background {
        display: none;
    }
    .filter-title-wrapper {
        box-shadow: none;
        padding-bottom: 0;
    }
    .filter-title {
        font-size: 20px;
        line-height: 30px;
        font-weight: 400;
    }
}
