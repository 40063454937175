.details-page-block {
    width: 100%;
    max-width: 1372px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 30px;
}

/* .details-container {
    margin-left: auto;
    margin-right: auto;
} */

.header {
    height: 84px;
    padding-top: 48px;
    /* padding-left: 304px; */
    /* position: relative; */
}

.bread-crumbs-wrapper {
    padding: 24px 0;
}

.bread-crumbs-wrapper > span {
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    color: #737373;
    margin-right: 10px;
    cursor: pointer;
}
.bread-crumbs-wrapper > span:last-child {
    color: #000000;
    cursor: unset;
}

.hero-image-wrapper {
    height: 292px;
}

.details-image {
    max-width: 100%;
    height: auto;
    border-radius: 16px;
}

.details-content-wrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 48px;
}

.details-content {
    width: 68%;
    padding-right: 7vw;
}

.vacancy-title {
    font-family: 'eUkraineHead';
    font-weight: 500;
    font-size: 54px;
    line-height: 66px;
    margin-bottom: 32px;
}

.details-item-title {
    font-family: 'eUkraineHead';
    font-weight: 400;
    font-size: 24px;
    line-height: 38px;
    margin-top: 32px;
    margin-bottom: 16px;
}

.details-item-content-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.details-item-content-wrapper:last-child {
    margin-bottom: 0;
}

.point {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #34773b;
    padding-left: 6px;
}

.details-item-content,
.details-list-item {
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
}

.details-list-item {
    padding-left: 17px;
}

.details-info-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
}

.details-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;
    background: #f8f8f8;
    border-radius: 16px;
    height: fit-content;
    width: 100%;
}

.apply-job-button-m {
    width: 100%;
}

.link-actions-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 40px 0 40px;
    width: 100%;
}

.action-button {
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 10px 24px; */
    width: 156px;
    height: 44px;
    border: 1.5px solid #34773b;
    border-radius: 8px;
    cursor: pointer;
}

.mr-5 {
    margin-right: 5px;
}

@media only screen and (max-width: 768px) {
    .details-page-wrapper {
        width: 100%;
    }
    .details-container {
        width: 100%;
    }
    .details-content-wrapper {
        padding-top: 20px;
        flex-direction: column;
    }
    .details-content {
        width: auto;
    }
    .hero-image-wrapper {
        height: auto;
    }
    .vacancy-title {
        font-size: 26px;
        line-height: 38px;
        font-weight: 500;
    }
    .bread-crumbs-wrapper {
        white-space: nowrap;
        overflow-x: auto;
    }
    .bread-crumbs-wrapper > span {
        font-size: 12px;
        line-height: 16px;
    }
    .details-page-block {
        padding: 0 16px;
    }
    .details-item-title {
        margin-top: 24px;
        font-size: 14px;
        line-height: 22px;
        font-weight: 700;
    }
    .details-item-content {
        font-size: 14px;
        line-height: 22px;
    }
    .details-list-item {
        font-size: 14px;
        line-height: 22px;
    }
    .buttons-wrapper {
        margin-top: 32px;
    }
    .link-actions-wrapper {
        padding: 0;
        margin-bottom: 24px;
    }
    .details-info {
        padding: 24px 20px;
    }
}
