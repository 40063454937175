.job-details-item:first-child {
    margin-bottom: 24px;
}

.job-details-item:last-child {
    opacity: 0.5;
}

.details-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 12px;
}

.details-list {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
}

.details-item {
    padding-left: 17px;
    text-indent: -17px;
    margin-bottom: 12px;
}

.details-item:before {
    content: "•";
    color: #34773b;
    padding-right: 8px;
}

.details-item:last-child {
    margin-bottom: 0;
}

/* ::marker {
    margin-right: 5px !important;
} */

@media only screen and (max-width: 768px) {
    .details-title {
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
    }
    .details-item {
        font-size: 14px;
        line-height: 22px;
    }
}
