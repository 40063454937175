.application-form {
  width: 100%;
  max-width: 640px;
}

.appliaction-form__sub-title {
  font-size: 12px;
  font-family: 'e-Ukraine', Arial, Helvetica, sans-serif;
  color: #737373;
}

.appliaction-form__title {
  margin-bottom: 32px;
  font-size: 40px;
  font-weight: 500;
  word-break: break-word;
}

.appliaction-form__section-title {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 24px;
}

.appliaction-form__section-title-small {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
}

.form-input {
  width: 100%;
  height: 44px;
  padding: 12px 16px 12px 16px;
  border: 1px solid #cacaca;
  border-radius: 4px;
}

.form-input-label {
  display: block;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 6px;
}

.form-input.with-error {
  border: 1px solid #d12c35;
}

.field-set {
  margin-bottom: 24px;
  position: relative;
}

.fields-row {
  display: flex;
  justify-content: space-between;
}

.fields-row > div {
  flex: 1;
}

.fields-row > div:not(:last-of-type) {
  margin-right: 24px;
}

.field-set .input-feedback {
  position: absolute;
  right: 0;
  margin-top: 4px;
  text-align: end;
  font-size: 12px;
  font-weight: 400;
  color: #d12c35;
}

.description-text {
  font-family: 'e-Ukraine', Arial, Helvetica, sans-serif;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 300;
}

.checkbox-form-group {
  display: block;
  margin-bottom: 24px;
  padding-left: 24px;
}

.checkbox-form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checkbox-form-group label {
  position: relative;
  cursor: pointer;
  font-family: 'e-Ukraine', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 300;
}

.checkbox-form-group label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1.5px solid #34773b;
  padding: 6.5px;
  border-radius: 4px;
  display: inline-block;
  position: absolute;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 12px;
  left: -24px;
  top: 3px;
}

.checkbox-form-group input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  width: 3px;
  height: 7px;
  left: -18px;
  top: 6px;
  border: solid #34773b;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.submit-button {
  padding: 13px 48px;
  background: #34773b;
  border-radius: 8px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  border: none;
  cursor: pointer;
}

.submit-button[disabled] {
  background: #737373;
  cursor: default;
}

.divider {
  border-top: 1px solid #d8d8d8;
  margin: 32px 0 24px 0;
}

@media only screen and (max-width: 768px) {
  .appliaction-form__title {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
  }
  .appliaction-form__section-title {
    font-size: 14px;
  }
  .appliaction-form__sub-title {
    font-size: 10px;
    line-height: 16px;
  }
  .fields-row > div:not(:last-of-type) {
    margin-right: 0;
  }
  .appliaction-form__section-title-small {
    font-size: 14px;
  }
  .description-text,
  .checkbox-form-group label {

    font-size: 14px;
    line-height: 22px;
  }
  .submit-button {
    width: 100%;
  }
}
