.short-details-wrapper {
    display: flex;
    align-items: flex-start;
    padding: 24px 0;
    box-shadow: inset 0px -1px 0px #cacaca;
}

.item-wrapper {
    display: flex;
    align-items: center;
    width: 59%;
    cursor: pointer;
    /* padding-bottom: 15px; */
}

.job-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    margin-left: 18px;
    /* width: 603px; */
}

.location {
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    width: 40%;
}

@media only screen and (max-width: 600px) {
    .item-wrapper {
        width: 100%;
    }
    .job-title {
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
    }
    .location {
        margin-left: 18px;
        font-size: 14px;
        line-height: 22px;
        width: auto;
    }
}
