.subcategory-item-wrapper {
    display: flex;
    align-items: center;
    /* cursor: pointer; */
    margin-bottom: 16px;
}

.subcategory-item-title {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    margin: 0 8px;
    cursor: pointer;
}
