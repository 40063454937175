.greeting-background {
    width: 100%;
    background: #f8f8f8;
    padding: 80px 40px 80px 304px;
}

.greeting-wrapper {
    display: flex;
    align-items: center;
}

.greeting-wrapper > img {
    width: 35%;
}

.greeting-text-wrapper {
    max-width: 637px;
    margin-left: 112px;
}

.greeting-title {
    font-family: 'eUkraineHead', Arial, Helvetica, sans-serif;
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;
    margin-bottom: 24px;
}

@media only screen and (max-width: 1200px) {
    .greeting-background {
        padding-left: 80px;
    }
    .greeting-wrapper {
        flex-direction: column;
        align-items: baseline;
    }
    .greeting-text-wrapper {
        margin-left: 0;
        margin-top: 40px;
    }
    .greeting-wrapper > img {
        width: 50%;
    }
}

@media only screen and (max-width: 768px) {
    .greeting-background {
        padding: 16px;
        height: auto;
    }
    .greeting-wrapper {
        flex-direction: column;
        padding: 0;
    }
    .greeting-wrapper > img {
        width: 100%;
    }
    .greeting-text-wrapper {
        width: auto;
        margin-left: 0;
        margin-top: 12px;
    }
    .greeting-title {
        font-size: 26px;
        line-height: 38px;
        font-weight: 500;
    }
}
