input[type='file'] {
  display: none;
}

.custom-file-upload {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 10px 32px;
  border: 1.5px solid #34773b;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  color: #34773b;
  font-weight: 700;
  position: relative;
}

.upload-section {
  margin-bottom: 24px;
}

.upload-img {
  margin-right: 8px;
}

.file-label {
  font-size: 12px;
  font-weight: 300;
  color: #737373;
  margin-bottom: 16px;
}

.file-info-wrap {
  display: flex;
  justify-content: space-between;
}

.file-info {
  display: flex;
}

.file-info-description {
  margin-left: 24px;
}

.file-info-name {
  font-size: 16px;
  font-family: 'e-Ukraine', Arial, Helvetica, sans-serif;
  margin-bottom: 4px;
}

.file-info-size {
  font-size: 12px;
  font-family: 'e-Ukraine', Arial, Helvetica, sans-serif;
  color: #737373;
}

@media only screen and (max-width: 600px) {
  .custom-file-upload {
    width: 100%;
    justify-content: center;
  }
}
