.subcategory-filter-item-wrapper {
    display: flex;
    align-items: center;
    /* cursor: pointer; */
    padding: 6px 0;
    margin: 6px 0;
}

.subcategory-filter-item-wrapper.checked {
    background-color: #ebf1eb;
}

.subcategory-filter-item-wrapper:hover {
    background-color: #f8f8f8;
}

.subcategory-item-title {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    margin: 0 8px;
    cursor: pointer;
}
