.job-overview-wrapper {
    max-width: 100%;
    display: flex;
    height: 462px;
    padding: 40px;
    background-color: #ffffff;
    box-shadow: 0px -1px 0px #d8d8d8;
}

.details-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-right: 40px;
    width: 60%;
}

.details-list-wrapper {
    margin-bottom: 24px;
    height: 314px;
    overflow: hidden;
}

.details-buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.details-buttons-wrapper > .button:first-child {
    margin-right: 12px;
}

.job-info-wrapper {
    box-shadow: inset 1px 0px 0px #cacaca;
    padding: 32px 34px;
    width: 40%;
}

@media only screen and (max-width: 1200px) {
    .details-buttons-wrapper {
        flex-direction: column;
    }
    .details-buttons-wrapper > button {
        width: 100%;
        margin-bottom: 12px;
    }
}

@media only screen and (max-width: 768px) {
    .job-overview-wrapper {
        height: auto;
        flex-direction: column;
        padding: 16px;
    }
    .details-wrapper {
        padding-right: 0;
        width: 100%;
    }
    .details-list-wrapper {
        height: auto;
    }
    .details-buttons-wrapper {
        flex-direction: row;
        align-items: flex-start;
    }
    .details-buttons-wrapper > button {
        width: 100%;
        margin-bottom: 0;
        padding: 12px 16px;
    }
    .job-info-wrapper {
        box-shadow: none;
        padding: 12px 0;
    }
}
