.date-picker-wrap {
  position: relative;
}

.date-picker-wrap > img {
  position: absolute;
  top: 12px;
  right: 16px;
  transform: translate(0, 10%);
}

.date-picker-wrap > div {
  position: relative;
}

/* Native picker */
.datepicker-toggle {
  display: block;
  position: relative;
  font-size: 14px;
}

.datepicker-toggle .placeholder {
  opacity: 0.5;
}
.datepicker-toggle-button {
  position: absolute;
  top: 12px;
  right: 16px;
  transform: translate(0, 10%);
  width: 16px;
  height: 16px;
}

.datepicker-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  box-sizing: border-box;
  background: transparent;
}
.datepicker-input::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
