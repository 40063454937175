.terms-wrapper {
    width: 100%;
    max-width: 1372px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 30px;
}

.hero-image-wrapper {
    height: 292px;
}

.mt {
    margin-top: 75px;
}

.terms-image {
    max-width: 100%;
    height: auto;
    border-radius: 16px;
}

.terms-content {
    width: 83%;
    padding-top: 48px;
}

.text-block {
    margin-bottom: 48px;
}

.text-block a {
    cursor: pointer;
    word-wrap: break-word;
    color: #1d5c24;
}

.text-block:last-child {
    margin-bottom: 0;
}

.terms-title {
    font-family: 'eUkraineHead';
    font-weight: 500;
    font-size: 54px;
    line-height: 66px;
    margin-bottom: 12px;
}

.terms-item-title {
    font-family: 'eUkraineHead';
    font-weight: 400;
    font-size: 24px;
    line-height: 38px;
    margin-bottom: 8px;
}

.terms-text {
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
}

.terms-text-small {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 24px;
}

.terms-text-small:last-child {
    margin-bottom: 0;
}

.list-item-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding-left: 5px;
}

.list-item-wrapper:last-child {
    margin-bottom: 0;
}

.point {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #34773b;
    padding-left: 6px;
}

.pl {
    padding-left: 17px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-24 {
    margin-bottom: 24px;
}

.mt-24 {
    margin-top: 24px;
}

@media only screen and (max-width: 768px) {
    .terms-wrapper {
        padding: 0 16px;
    }
    .hero-image-wrapper {
        height: auto;
    }
    .terms-content {
        width: auto;
        padding-top: 24px;
    }
    .text-block {
        margin-bottom: 32px;
    }
    .terms-title {
        font-size: 30px;
        line-height: 42px;
    }
    .terms-text {
        font-size: 14px;
        line-height: 22px;
    }
    .terms-item-title {
        font-size: 16px;
        line-height: 26px;
    }
    .font-mobile {
        font-size: 12px;
        line-height: 16px;
    }
}
