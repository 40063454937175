.search-dropdown {
    position: absolute;
    width: 100%;
    background: #ffffff;
    padding: 16px 0;
    border: 1px solid #cacaca;
    border-radius: 8px;
    border-top: none;
    /* TO-DO: поки не придумав як зробити */
    transition: 1s;
}

.input-wrapper {
    padding: 0 16px;
}

.dropdown-item {
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
    cursor: pointer;
    height: 40px;
    /* TO-DO: check padding */
    padding: 16px;
}

.dropdown-item::first-letter {
    text-transform: capitalize;
}

.dropdown-item:hover {
    background-color: #ebf1eb;
}

.search-field {
    /* font-family: 'e-Ukraine';
font-style: normal; */
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    padding: 12px 16px 12px 40px;
    width: 256px;
    height: 44px;
    background: #ffffff;
    border: 1px solid #cacaca;
    border-radius: 4px;
    background: url('../../assets/search.svg') no-repeat;
    background-position-x: 16px;
    background-position-y: 12px;
    outline: 0;
    transition: 0.5s;
}

.search-field:focus {
    border: 1px solid #000000;
}

.search-field::placeholder {
    color: #737373;
    font-weight: 300;
    font-size: 14px;
}

.search-field:focus::-webkit-input-placeholder {
    color: transparent;
}

@media only screen and (max-width: 768px) {
    .search-dropdown {
        height: 40%;
        overflow: hidden;
        bottom: 0;
        left: 0;
        position: fixed;
    }
    .items-wrap-inner {
    }
    .items-wrap {
        overflow-y: auto;
        height: 80%;
    }
    .search-field {
        width: 100%;
    }
}
