.dropdown-input-field {
  position: relative;
  width: 100%;
  border: 1px solid #cacaca;
  border-radius: 4px;
}

.dropdown-input-value {
  font-size: 14px;
  font-weight: 300;
  text-transform: capitalize;
  background: url('../../assets/arrowdown.svg') no-repeat right;
  -webkit-appearance: none;
}

.open {
  background: url('../../assets/arrow-up-green.svg') no-repeat right;
  -webkit-appearance: none;
  background-position-x: 95%;
  border: none;
}

.dropdown-select {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  background: #ffffff;
  padding: 16px;
  border: 1px solid #cacaca;
  z-index: 1;
}

.select-item {
  margin: 0;
  list-style: none;
  height: 40px;
  padding: 12px 16px;
  font-weight: 300;
  font-size: 14px;
  cursor: pointer;
}

.select-item::first-letter {
  text-transform: capitalize;
}

.select-item:hover {
  background-color: #ebf1eb;
}
