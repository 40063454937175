.info-wrapper {
    display: flex;
    padding-bottom: 32px;
    align-items: flex-start;
}
.info-wrapper:last-child {
    padding-bottom: 0;
}

.info-content-wrapper {
    text-align: start;
    margin-left: 15px;
}

.info-item-title {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #737373;
}

.info-item-content {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
}
