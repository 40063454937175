.sorting-item-wrapper {
    display: flex;
    align-items: center;
}

.sorting-item {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    margin-right: 8px;
}

.sorting-item-wrapper:first-child {
    /* margin-right: 565px; */
    min-width: 59%;
}

.sorting-directions-wrapper {
    display: flex;
    flex-direction: column;
    height: 14px;
    justify-content: space-between;
    cursor: pointer;
}
