.hero-wrapper {
    width: 100%;
    height: 700px;
    background-color: #ffffff;
    display: flex;
    justify-content: end;
    position: relative;
}

@media only screen and (max-width: 1200px) {
    .hero-wrapper {
        height: 800px;
    }
}
@media only screen and (max-width: 768px) {
    .hero-wrapper {
        flex-direction: column;
        padding: 40px 16px;
        height: auto;
    }
}
