.text {
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    margin-bottom: 8px;
}

@media only screen and (max-width: 600px) {
    .text {
        font-size: 14px;
        line-height: 22px;
    }
}
