.subcategory-block {
    margin-bottom: 12px;
}

.subcategory-block:last-child {
    margin-bottom: 0;
}

.subcategory-title-wrapper {
    display: flex;
    /* justify-content: space-between; */
    margin-bottom: 16px;
    align-items: center;
}

.subcategory-item-wrapper:last-child {
    margin-bottom: 0;
}

.subcategory-checkbox-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.checkbox-checker {
    width: 16px;
    height: 16px;
    border: 1.5px solid #737373;
    border-radius: 4px;
    /* transform: matrix(1, 0, 0, -1, 0, 0); */
    position: relative;
    cursor: pointer;
}

.checkbox-checker-c {
    border: 1.5px solid #34773b;
}

.subcategory-checked {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.subcategory-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    margin: 0 8px;
}

.subcategory-title-l {
    font-weight: 300;
    line-height: 20px;
}

.subcategory-jobs-number {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #737373;
    cursor: pointer;
}
