.main-header {
    width: 100%;
    padding-top: 48px;
    display: flex;
    align-items: center;
    background: #ffffff;
    justify-content: space-between;
}

.main-header.absolute {
    position: absolute;
    z-index: 100;
    padding-left: 15.8%;
    padding-right: 15.8%;
    background-color: transparent;
}

.lang-switch {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 86px;
    height: 34px;
    background: #ffffff;
    border: 2px solid rgba(255, 255, 255, 0.32);
    box-shadow: 0px 4px 24px rgba(224, 224, 224, 0.32);
    border-radius: 8px;
}

.text-btn {
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 4px;
    font-size: 16px;
    color: black;
}

.text-btn.active {
    color: #34773b;
}

.text-btn:last-of-type {
    margin-left: 4px;
}

.devider {
    height: 100%;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    color: #eeeeee;
    margin-left: 8px;
    margin-right: 8px;
}

@media only screen and (max-width: 1200px) {
    .main-header.absolute {
        padding-left: 80px;
    }
}

@media only screen and (max-width: 768px) {
    .main-header {
        justify-content: space-between;
        position: static;
        padding-left: 0;
        padding-right: 0;
    }
    .main-header.absolute {
        position: static;
        padding-left: 16px;
        padding-right: 16px;
        background: #fff;
    }
}

@media only screen and (max-width: 600px) {
    .main-header {
        justify-content: space-between;
        position: static;
        padding-left: 0;
        padding-right: 0;
    }
    .main-header.absolute {
        position: static;
        padding-left: 16px;
        padding-right: 16px;
        background: #fff;
    }
}
