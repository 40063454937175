.job-section-block {
    max-width: 1920px;
    background: #f8f8f8;
    padding: 88px 20px;
}

.jobssection-wrapper {
    width: 57%;
    max-width: 1088px;
    /* background: #f8f8f8; */
    margin-left: auto;
    margin-right: auto;
}

.title-wrapper {
    display: flex;
    flex-direction: column;
    box-shadow: inset 0px -1px 0px #cacaca;
    padding-bottom: 24px;
    margin-bottom: 32px;
}

.clear-wrapper {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
    padding: 5px;
    margin-left: auto;
    margin-right: 40px;
}

.clear-wrapper > p {
    margin-left: 11px;
}

.title-top-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title-bottom-wrapper {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    flex-wrap: wrap;
    max-width: 59%;
    padding-top: 12px;
}

.line-category-wrapper {
    display: flex;
    align-items: center;
    margin-right: 22px;
}

.line-category-title {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #737373;
    margin-right: 8px;
}

.title {
    font-family: 'eUkraineHead';
    font-weight: 400;
    font-size: 32px;
    line-height: 44px;
}

.filter-job-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
}

/* .filter-job-button:first-child {
    margin-right: 11px;
} */

.button-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #34773b;
    margin-left: 11px;
}

.sorting-wrapper {
    display: flex;
    box-shadow: inset 0px -1px 0px #cacaca;
    padding-bottom: 16px;
    /* margin-bottom: 24px; */
}

/* .sorting-wrapper:first-child {
    margin-bottom: 2px;
} */


@media only screen and (max-width: 768px) {
    .jobssection-wrapper {
        width: 100%;
        padding: 0;
    }
    .job-section-block {
        padding: 20px;
    }
    .title-top-wrapper {
        align-items: center;
    }
    .filter-job-button {
        padding: 8px;
    }
    .title {
        margin-bottom: 0;
        font-size: 26px;
        line-height: 38px;
        font-weight: 500;
    }
    .clear-wrapper {
        margin-right: 0;
        margin-left: 0;
    }
    .title-bottom-wrapper {
        max-width: 100%;
        padding-top: 16px;
    }
    .line-category-title {
        margin-bottom: 8px;
        font-size: 10px;
        line-height: 16px;
        font-weight: 500;
    }
    .line-category-wrapper > img {
        margin-bottom: 8px;
    }
}
