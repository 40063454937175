.white {
    background-color: #ffffff;
}

.error-page-wrapper {
    width: 100%;
    height: 100vh;
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 25px;
    display: flex;
    flex-direction: column;
}

.error-page-wrapper .content-wrapper {
    width: 100%;
    flex: 1;
    padding: 125px 0;
}

.error-button {
    width: 173px;
    margin-left: auto;
    margin-right: auto;
    border: none;
    display: block;
}

.error-title {
    font-family: 'eUkraineHead';
    font-weight: 500;
    font-size: 54px;
    line-height: 66px;
    /* margin-left: auto;
    margin-right: auto; */
    text-align: center;
    margin-bottom: 24px;
}

.error-text {
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    margin-bottom: 32px;
}

@media only screen and (max-width: 768px) {
    .hero-wrapper {
        flex-direction: column;
        padding: 12px 16px;
        height: auto;
    }
    .error-title {
        font-size: 30px;
        line-height: 42px;
        font-weight: 500;
    }
    .error-text {
        font-size: 14px;
        line-height: 22px;
    }
}

