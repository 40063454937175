.map-wrapper {
    overflow: hidden;
    width: 897px;
    height: 700px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    position: relative;
}

.map {
    position: relative;
}

.map svg {
    /* transition: .3s transform;
    will-change: transform; */
}

.tooltip {
    position: absolute;
    z-index: 1999;
    opacity: 0;
    pointer-events: none;
    /* transition: .3s opacity;
    will-change: opacity; */
}

.tooltip-content {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 48px;
    background-color: #ffffff;
}

.tooptip:hover,
.tooltip.active {
    opacity: 1;
}

.tooltip.hidden {
    opacity: 0;
}

.country-flag {
    width: 18px;
    height: 18px;
    margin-right: 8px;
}

.country-title {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 1;
    color: #000000;
    padding: 0;
}

.country-title::first-letter {
    text-transform: capitalize;
}

.country-jobs {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
}

.zoom-buttons {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 32px;
    right: 32px;
}

.zoom-button {
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: 1.5px solid #34773b;
    border-radius: 8px;
    box-sizing: border-box;
    transition: 0.3s;
    margin-left: 24px;
}

.zoom-button:hover {
    transform: scale(1.1);
}

.zoom-button[disabled] {
    pointer-events: none;
    opacity: 0.5;
}

.map-specification-wrapper {
    background-color: #ebf1eb;
    border-radius: 4px;
    display: flex;
    margin-top: 12px;
    margin-bottom: 12px;
    padding: 8px 8px 8px 12px;
}

.map-specification-wrapper .tooltip-content {
    background: none;
    padding: 0;
}

@media only screen and (max-width: 768px) {
    .map-wrapper {
        padding: 0;
        margin-left: -16px;
        margin-right: -16px;
        height: 45%;
        width: calc(100% + 32px);
    }

    .map {
        padding-left: 16px;
        padding-right: 16px;
        overflow: auto;
    }

    .map > svg {
        width: 100%;
        height: auto;
    }

    .zoom-buttons {
    }

    .tooltip-content {
        padding: 3px 6px;
    }

    .tooltip .country-title {
        font-size: 9px;
    }

    .tooltip .country-flag {
        width: 12px;
        height: 12px;
        margin-right: 4px;
    }

    .zoom-buttons {
        top: 16px;
        right: 16px;
    }

    .zoom-button {
        width: 32px;
        height: 32px;
        background: #ffffff;
        margin-left: 16px;
    }

    .zoom-button:hover {
        transform: scale(1);
    }
}
