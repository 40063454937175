#austria,
#hungary,
#slovenia,
#netherlands,
#spain,
#kosovo,
#malta,
#ireland,
#georgia,
#denmark,
#albania,
#belgium,
#croatia,
#norway,
#poland,
#switzerland,
#italy,
#nireland,
#scotland,
#grancanaria,
#nengland,
#blakemore {
    cursor: pointer;
}

a {
    all: unset;
}

.app-content-wrapper {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.app-background {
    background-color: #f5f5f5;
}

.button-primary,
.button {
    background: #ffffff;
    color: #34773b;
    border: 1.5px solid #34773b;
    padding: 12px 32px;
    display: inline-block;
    border-radius: 8px;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    transition: 0.3s;
    box-sizing: border-box;
}

.button:hover {
    border: 1.5px solid #1d5c24;
    color: #1d5c24;
}

.button-primary {
    background-color: #34773b; /* Green */
    color: white;
}

.button-primary:hover {
    background-color: #1d5c24;
}

.button-full-with {
    flex: 1;
}

.mr-12 {
    margin-right: 12px;
}

@media only screen and (max-width: 600px) {
    .app-content-wrapper {
        max-width: 600px;
    }
}
