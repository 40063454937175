.vacancies-wrapper {
    background-color: #fff;
    position: absolute;
    width: 710px;
    top: 164px;
    left: 15.8%;
    z-index: 2000;
}

.vacancies-title {
    font-family: 'eUkraineHead', Arial, Helvetica, sans-serif;
    font-size: 54px;
    font-weight: 500;
    line-height: 66px;
    margin-bottom: 24px;
}

.specification-wrapper {
    background-color: #f8f8f8;
    border-radius: 4px;
    display: flex;
    width: 580px;
    margin-top: 24px;
    margin-bottom: 32px;
    padding: 8px 8px 8px 12px;
}

.specification-subtext {
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    margin-left: 10px;
}

.shadow {
    box-shadow: 0px 4px 24px rgba(224, 224, 224, 0.32);
}

.search-wrapper {
    width: 830px;
    background-color: #ffffff;
    padding: 14px 12px;
    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.32);
    border-radius: 8px;
    display: flex;
    justify-content: space-evenly;
    position: relative;
}

.search-item-wrapper {
    margin-right: 14px;
    position: relative;
}

.search-item {
    padding: 12px 16px;
    width: 100%;
    height: 44px;
    background: #ffffff;
    border: 1px solid #cacaca;
    border-radius: 4px;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #737373;
    background: url('../../assets/arrowdown.svg') no-repeat right;
    -webkit-appearance: none;
    background-position-x: 95%;
    cursor: pointer;
    margin-bottom: 1px;
}

.search-item:first-child {
    width: 288px;
}

.si-open {
    background: url('../../assets/arrow-up-green.svg') no-repeat right;
    border: 1px solid #000000;
    -webkit-appearance: none;
    background-position-x: 95%;
}

.filter-categories-wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    max-width: 1620px;
    width: 70vw;
    height: 462px;
    padding: 24px;
    background: #ffffff;
    border: 1px solid #cacaca;
    box-shadow: 0px 4px 24px rgba(224, 224, 224, 0.32);
    border-radius: 8px;
    /* border-top: none; */
    justify-content: space-between;
}

.filter-categories-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding-bottom: 24px; */
    margin-bottom: 12px;
}

.filter-categories-title {
    color: #131313;
    font-family: 'eUkraineHead';
    font-weight: 400;
    font-size: 24px;
    line-height: 38px;
}

.filter-categories-select-all {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #34773b;
    cursor: pointer;
    padding: 10px;
}

.filter-categories-items-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    height: 70%;
    overflow: auto;
}

.filter-categories-buttons {
    display: flex;
    justify-content: right;
    align-items: center;
    padding-top: 24px;
    width: 400px;
}

.filter-categories-buttons-wrap {
    display: flex;
    justify-content: flex-end;
}

@media only screen and (max-width: 968px) {
    .search-wrapper {
        flex-direction: column;
        width: 100%;
        height: auto;
        align-items: center;
    }
    .search-item-wrapper {
        margin-right: 0;
        margin-bottom: 12px;
        width: 70%;
    }
    .search-item:first-child {
        width: 100%;
    }
    .search-wrapper > button {
        position: static;
        margin-top: 12px;
        width: 50%;
    }
    .filter-categories-wrapper {
        z-index: 2000;
        left: -50%;
    }
    .vacancies-wrapper {
        width: 70%;
        padding: 24px;
    }
    .specification-wrapper {
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .vacancies-wrapper {
        position: static;
        width: 100%;
        padding: 0;
    }
    .specification-wrapper {
        width: 100%;
        margin: 24px 0;
    }
    .search-wrapper {
        flex-direction: column;
        width: 100%;
        height: auto;
        align-items: center;
    }
    .search-item-wrapper:first-child {
        width: 100%;
        max-width: unset;
        min-width: unset;
        margin-bottom: 24px;
    }
    .search-item-wrapper {
        position: static;
        width: 100%;
        margin-right: 0;
        margin-bottom: 24px;
    }
    .search-item-wrapper > div,
    .search-item-wrapper:first-child > div,
    .search-item:first-child {
        width: 100%;
    }
    .search-wrapper > button {
        position: static;
        margin-top: 12px;
        width: 100%;
    }
    .filter-categories-wrapper {
        height: auto;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .filter-categories-items-wrapper {
        height: 75%;
        overflow: auto;
        flex-wrap: nowrap;
    }
    .subcategory-i-block {
        width: auto;
    }
    .filter-categories-buttons {
        flex-direction: column;
        align-items: center;
    }
    .filter-categories-buttons > button {
        width: 100%;
        margin-bottom: 12px;
        margin-right: 0;
    }
    .specification-subtext {
        font-size: 10px;
    }
    .vacancies-title {
        font-size: 28px;
        line-height: 42px;
        margin-bottom: 16px;
    }
    .search-wrapper-mobile {
        padding: 16px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        z-index: 2000;
        height: calc(100vh - calc(100vh - 100%));
        overflow: auto;
        padding-bottom: 40px;
    }
    .search-wrapper-mobile .button-primary {
        width: 100%;
    }
    .handle-search {
        width: 100%;
        margin: 24px 0;
    }
    .go-back-wrap {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        /*margin-top: 24px;*/
        font-size: 20px;
        line-height: 30px;
        font-weight: 400;
    }
    .back-btn {
        background: none;
        border: none;
        padding: 0;
        margin-right: 8px;
        width: 24px;
        height: 24px;
    }
    .filter-categories-wrapper {
        padding: 16px;
    }
    .filter-categories-title {
        font-size: 20px;
        line-height: 30px;
        font-weight: 400;
    }
    .filter-categories-close {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .filter-categories-title-wrapper {
        margin-bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
